import React from 'react';
import './/StyleComponents/HomeEducation.css';




const HomeEducation = () => {

    return (

        <div>
            <div className="HomeEducationContainer">
                <h1>
                    Education
                </h1>
                <div className="ConcordiaEducation">
                    <h1>
                        Concordia University
                    </h1>
                    <h2>
                        BEng-Software Engineering
                    </h2>
                    <ul>
                        <li>
                            <b>Core engineering:</b>
                            <br/>
                            <p>
                                Applied Ordinary Differential Equations, Applied Advanced
                                Calculus, Intro business & management,
                                Composition & argumentation for
                                engr, Probability+Statistics,
                                Principles of electrical engineering, Numerical methods
                            </p>
                        </li>
                        <li>
                            <b>Software engineering:</b>
                            <br/>
                            <p>
                                Intro to formal methods, Software architecture& Design,
                                Software testing & QA, UI Design, Software Process, Control Systems, System
                                Security, System Hardware
                            </p>
                        </li>
                        <li>
                            <b>Computer science:</b>
                            <br/>
                            <p>
                                Object-Oriented Programming, Theoretical Computer Science,
                                Data Structures & Algorithms, Principles of Programming Language, Operating
                                systems, Databases, Advanced Program Design with C++, Data communication &
                                Network, Artificial Intelligence
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="M9Education">
                    <h1>
                        Collège de Maisonneuve
                    </h1>
                    <h2>
                        DEC- Natural Science
                    </h2>
                    <ul>
                        <li>
                            <b>Science:</b>
                            <br/>
                            <p>
                                Differential Calculus, Integral Calculus, Linear Algebra, Chemistry
                                of solutions, Organic Chemistry, Body Functions & Life Process, Evolution &
                                diversity of life, Mechanics, Electricity & Magnetism, Waves & Modern
                                Physics
                            </p>
                        </li>
                        <li>
                            <b>French:</b>
                            <br/>
                            <p>
                                Littérature et imaginaire, Écriture et littérature, Littérature
                                québécoise, Médias et communication
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HomeEducation;