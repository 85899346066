import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import TWEEN from '@tweenjs/tween.js';
import { gsap } from 'gsap';
import useIntersectionObserver from './useIntersectionObserver';

function FirstAnimation() {
    const canvasRef = useRef(null);
    const isVisible = useIntersectionObserver(canvasRef, { rootMargin: '100px' });


    useEffect(() => {
        if (!isVisible) return;


        const scene = new THREE.Scene();

        //Camera
        const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0,0,0);
        camera.rotation.set(0,0.1,0);

        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
        renderer.setSize(window.innerWidth, window.innerHeight);

        const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);
        scene.add(ambientLight);


        // Setting up the loaders
        /*const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
        dracoLoader.setDecoderConfig({ type: 'js' });*/

        const desktopManager = new THREE.LoadingManager();

        desktopManager.onStart=function (url,item,total){
            console.log("Started loading desktop");
        }



        desktopManager.onError=function(url){
            console.error("ERROR LOADING DESKTOP");
        }

        const progressBar=document.getElementById("progress-bar");

        desktopManager.onProgress=function (url,loaded,total){
            progressBar.value=(loaded/total)*100;
        }

        const progressBarContainer=document.querySelector('.progress-bar-container');
        const mainContainer = document.querySelector('.MainContainer');

        //Scrolling
        let position = 0;
        let lastPosition = -1;
        let totalHeight;
        let visibleHeight;
        let scrollableHeight;

        //Verify if it's a mobile, if it is, modify camera position

        let isMobile=false

        desktopManager.onLoad=function (){
            progressBarContainer.style.display='none';
            mainContainer.style.display='grid';

            //Scrolling
            position = 0;
            lastPosition = -1;
            totalHeight = mainContainer.scrollHeight;
            visibleHeight = mainContainer.clientHeight;
            scrollableHeight = totalHeight - visibleHeight;


            mobileView();
            if(!isMobile){
                mainContainer.addEventListener('scroll', throttle(scrollEffect,2000));
            }
        }

        const loader = new GLTFLoader();

        const desktopLoader = new GLTFLoader(desktopManager);




        function handleResize() {
            if (renderer && camera) {
                const width = window.innerWidth;
                const height = window.innerHeight;

                renderer.setSize(width, height); // update renderer size
                camera.aspect = width / height;  // update camera aspect ratio
                camera.updateProjectionMatrix(); // required after changing aspect ratio
            }
        }

        window.addEventListener('resize', handleResize);




        //Desktop model
        let desktopMesh;
        // Load the desktop model
        desktopLoader.load('gaming_desktop_pc/scene.gltf', function(gltf) {
            // Add the loaded object to the scene
            desktopMesh=gltf.scene


            desktopMesh.scale.set(0.1, 0.1, 0.1);

            desktopMesh.position.set(5.5,-1.7, 1);
            desktopMesh.rotation.y+=4

            scene.add(desktopMesh);
        }, undefined, function(error) {
            console.error(error);
        });


        // Portrait
        let siar_portrait;
        function addPortrait()
        {
            const siar_portrait_picture = new THREE.TextureLoader().load('portrait_image.png');
            siar_portrait_picture.anisotropy = renderer.capabilities.getMaxAnisotropy();
            siar_portrait = new THREE.Mesh(
                new THREE.BoxGeometry(1, 2, 0.01),
                new THREE.MeshBasicMaterial({map: siar_portrait_picture})
            );
            siar_portrait.position.set(-3.4, 2, 1.4);
            siar_portrait.rotation.y = 0.5;
            scene.add(siar_portrait); //Don't add if it is mobile view
        }


        let soccerMesh;
        function addSoccerBall(){
            // Load the soccer model
            // Don't add if it is mobile view
            loader.load('soccer_ball/scene.gltf', function (gltf) {
                // Add the loaded object to the scene
                soccerMesh = gltf.scene

                soccerMesh.position.set(11, -2, -1);
                scene.add(soccerMesh);
            }, undefined, function (error) {
                console.error(error);
            });
        }

        /*let logoMesh;
        function addLogoModel() {
            // Load the logo model
            // Don't if it is mobile view
            loader.load('logotipos_3d_-_aprenda_programar/scene.gltf', function (gltf) {
                // Add the loaded object to the scene
                logoMesh = gltf.scene

                logoMesh.scale.set(0.1, 0.1, 0.1);

                logoMesh.position.set(5.8, 3.2, 2);

                scene.add(logoMesh);
            }, undefined, function (error) {
                console.error(error);
            });
        }*/



        function mobileView(){
            let visibleWidth=mainContainer.clientWidth;
            if(visibleWidth<=800){
                isMobile=true;
                moveCamera(6, -1.7, 5.5);
            }
            else{
                isMobile=false;
                mainContainer.addEventListener('scroll', scrollEffect);
                if(desktopMesh){
                    desktopMesh.rotation.x=0;
                    desktopMesh.rotation.y=4;
                    desktopMesh.rotation.z=0;
                }

                if(!siar_portrait){
                    addPortrait();
                }

                if(!soccerMesh){
                    addSoccerBall();
                }
                /*if(!logoMesh){
                    addLogoModel();
                }*/
            }
        }




        function updateDimensions() {
            totalHeight = mainContainer.scrollHeight;
            visibleHeight = mainContainer.clientHeight;
            scrollableHeight = totalHeight - visibleHeight;
            mobileView();
            //Verify if it's mobile
        }

        function scrollEffect() {
            if (isMobile) return;
            const scrollTop = mainContainer.scrollTop;
            const scrollPercentage = (scrollTop / scrollableHeight) * 100;

            //Skip if it's mobile view(return)
            if (scrollPercentage <= 2.67) {
                position = 0;
            } else if (scrollPercentage <= 33.33) {
                position = 1;
            } else if (scrollPercentage <= 70) {
                position = 2;
            } else if (scrollPercentage <= 88) {
                position = 3;
            } else {
                position = 4;
            }

            // Only update if the position has changed
            if (position !== lastPosition) {
                updateScene();
                lastPosition = position;
            }

        }

        function updateScene() {
            switch (position) {
                case 0:
                    moveCamera(0, 0, 0);
                    rotateCamera(0, 0.1, 0);
                    mainContainer.style.background = "linear-gradient(rgba(0, 0, 0, 0.5), rgba(164, 5, 184, 0.2))";
                    break;
                case 1:
                    //moveCamera(2.8, 0, 3.6);
                    //rotateCamera(0, -2, 0);
                    moveCamera(-1.8, 1.6, 5);
                    rotateCamera(0, 0.1, 0);
                    mainContainer.style.background = "linear-gradient(rgba(0, 0,0, 0.4), rgba(164, 5, 184, 0.2))";
                    break;
                case 2:
                    moveCamera(4.5, -1.5, 3.2);
                    rotateCamera(0, 0, 0);
                    mainContainer.style.background = "linear-gradient(rgba(164, 5,184, 0.4), rgba(0, 0, 0, 1))";
                    break;
                case 3:
                    moveCamera(6.5, 3, 3.7);
                    rotateCamera(0, 0, 0);
                    mainContainer.style.background = "linear-gradient(rgba(0, 0,0, 0.5), rgba(164, 5, 184, 0.1))";
                    break;
                case 4:
                    moveCamera(9, -2, 4);
                    rotateCamera(0, 0, 0);
                    mainContainer.style.background = "linear-gradient(rgba(0, 0,0, 1), rgba(164, 5, 184, 0.3))";
                    break;
                default:
                    console.error("Unexpected position value: ", position);
                    moveCamera(-1.8, 1.6, 5);
                    rotateCamera(0, 0.1, 0);
                    break;
            }
        }

        function moveCamera(x, y, z) {
            gsap.to(camera.position, {
                duration: 4,
                x,
                y,
                z
            });
        }

        function rotateCamera(x, y, z) {
            gsap.to(camera.rotation, {
                duration: 4,
                x,
                y,
                z
            });
        }

        function throttle(func, limit) {
            let inThrottle;
            return function() {
                const args = arguments;
                const context = this;
                if (!inThrottle) {
                    func.apply(context, args);
                    inThrottle = true;
                    setTimeout(() => inThrottle = false, limit);
                }
            }
        }

        window.addEventListener('resize', updateDimensions);






        // Particles
        let particleSystem;
        function generateRandomPosition() {
            const range = 20;
            const position = new THREE.Vector3();
            position.x = Math.random() * range - range / 2;
            position.y = Math.random() * range - range / 2;
            position.z = Math.random() * range - range / 2;
            return position;
        }
        function initParticleSystem(numParticles) {
            const geometry = new THREE.BufferGeometry();
            const positions = [];
            for (let i = 0; i < numParticles; i++) {
                const position = generateRandomPosition();
                positions.push(position.x, position.y, position.z);
            }
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            const material = new THREE.PointsMaterial({ color: 0xffffff, size: 0.05 });
            particleSystem = new THREE.Points(geometry, material);
            scene.add(particleSystem);
        }
        initParticleSystem(100);

        const rotationDelta = 0.001;

        function animate(time) {
            TWEEN.update(time);


            if (particleSystem) {
                const rotation = particleSystem.rotation;
                rotation.y += rotationDelta;
                rotation.x += rotationDelta * 5;
                rotation.z += rotationDelta;
            }

            /*if (soccerMesh){
                const rotation = soccerMesh.rotation;
                rotation.x += rotationDelta * 10;
                rotation.y += rotationDelta * 50;
            }*

            /*if (logoMesh){
                logoMesh.rotation.y +=0.01;
            }*/

            // If mobile, rotate desktop when scrolling

            if(siar_portrait){
                siar_portrait.rotation.y += rotationDelta * 10;
            }

            if(isMobile && desktopMesh){
                desktopMesh.rotation.y += rotationDelta * 10;
            }



            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        }

        animate();


        return () => {
            renderer.dispose();
            mainContainer.removeEventListener('scroll', moveCamera);
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', updateDimensions);
            mainContainer.removeEventListener('scroll', throttle(scrollEffect, 100));
        };

    }, [isVisible]);

    return <canvas style={{ width: "100%", height:"100vh"}} ref={canvasRef} />;
}

export default FirstAnimation;
