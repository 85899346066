import React from 'react';
import './/StyleComponents/HomePassion.css';


const HomePassion = () => {

    return (

        <div>
            <div className="HomePassionContainer">
                <h1>
                    Passion
                </h1>
                <div>
                    <h1>
                        Sports
                    </h1>
                    <ul>
                        <li>
                            <h2>Soccer</h2>
                        </li>
                        <li>
                            <h2>Gym</h2>
                        </li>
                        <li>
                            <h2>Volley-ball</h2>
                        </li>
                        <li>
                            <h2>Running</h2>
                        </li>
                    </ul>
                </div>
                <div>
                    <h1> Traveling</h1>
                    <ul>
                        <li>
                            <h2>Portugual</h2>
                        </li>
                        <li>
                            <h2>Spain</h2>
                        </li>
                        <li>
                            <h2>United-States</h2>
                        </li>
                        <li>
                            <h2>Canada</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HomePassion;