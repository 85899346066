import React from 'react';
import './/StylePages/Blog.css';
import BlogContent from "../components/BlogContent";


const Blog=()=> {

    return (
        <div>
            <h1 className="BlogHeader" style={{marginTop: "2%",color:"white"}}>
                Blog
            </h1>
            <ul className="StoryList">
                <li>
                    <BlogContent title="Graduation" imgSrc="Graduation.png" paragraph="nfdjsvnfuivnif"></BlogContent>
                </li>
                <li>
                    <BlogContent title="Portugal 2023" imgSrc="Portugal.png" paragraph="nfdjsvnfuivnif"></BlogContent>
                </li>
                <li>
                    <BlogContent title="Illume chess " imgSrc="Graduation.png" paragraph="nfdjsvnfuivnif"></BlogContent>
                </li>
                <li>
                    <BlogContent title="Soccer AA win" imgSrc="Graduation.jpeg" paragraph="nfdjsvnfuivnif"></BlogContent>
                </li>
                <li>
                    <BlogContent title="ConU Hackhaton 2023" imgSrc="Graduation.jpeg" paragraph="nfdjsvnfuivnif"></BlogContent>
                </li>
                <li>
                    <BlogContent title="Spain 2022" imgSrc="Graduation.jpeg" paragraph="nfdjsvnfuivnif"></BlogContent>
                </li>
            </ul>

        </div>
    )

}


export default Blog;