import React, {useEffect, useState} from 'react';
import './/StylePages/Projects.css';
import Modal from 'react-modal';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        color:"white",
        marginRight: '-50%',
        textAlign:"justify",
        transform: 'translate(-50%, -50%)',
        width:'50%',
        zIndex:'1000!important',
        border:'solid 2px white',
        borderRadius:'20px',
        background:'black',
        fontSize:'2vw'
    },
};



const Projects = () => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [title, setTitle] = useState('');
    const [planetBarbierDirectory, setPlanetbarbier] = useState('PlanetBarbier_mobile.png');
    const [covidDirectory, setCovid] = useState('Mobile_covid_app.png');
    const [content, setContent] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //Mobile view for the modal
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    useEffect(()=>{

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // User is on mobile device, exit early.
            return;
        }


        if(windowWidth<=768){
            setPlanetbarbier("PlanetBarbier_mobile.png");
            setCovid("Mobile_covid_app.png");
        }
        else{
            setPlanetbarbier("PlaneteBarbier.jpeg");
            setCovid("Covid19App.png");
        }



        let el = document.getElementsByClassName('tilt');
        Array.from(el).forEach(entry=>{
            const height = entry.clientHeight;
            const width = entry.clientWidth;

            entry.addEventListener('mousemove', handleMove);

            /* Define function a */
            function handleMove(e) {
                /*
                  * Get position of mouse cursor
                  * With respect to the element
                  * On mouseover
                  */
                /* Store the x position */
                const xVal = e.layerX
                /* Store the y position */
                const yVal = e.layerY

                /*
                  * Calculate rotation valuee along the Y-axis
                  * Here the multiplier 20 is to
                  * Control the rotation
                  * You can change the value and see the results
                  */
                const yRotation = 20 * ((xVal - width / 2) / width)

                /* Calculate the rotation along the X-axis */
                const xRotation = -20 * ((yVal - height / 2) / height)

                /* Generate string for CSS transform property */
                const string = 'perspective(500px) scale(1.1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'

                /* Apply the calculated transformation */
                entry.style.transform = string
            }

            /* Add listener for mouseout event, remove the rotation */
            entry.addEventListener('mouseout', function() {
                entry.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
            })

            /* Add listener for mousedown event, to simulate click */
            entry.addEventListener('mousedown', function() {
                entry.style.transform = 'perspective(500px) scale(0.9) rotateX(0) rotateY(0)'
            })

            /* Add listener for mouseup, simulate release of mouse click */
            entry.addEventListener('mouseup', function() {
                entry.style.transform = 'perspective(500px) scale(1.1) rotateX(0) rotateY(0)'
            })
        })

    },[windowWidth])

    useEffect(()=>{
        const bodyHtml=document.querySelector("body");
        bodyHtml.style.overflow="auto";
    },[]);

    const mobileStyles = {
        content: {
            top: '50%',
            left: '50%',
            width: '80%',
            fontSize: '3.5vw',
            zIndex:'1000!important',
            border:'solid 2px white',
            borderRadius:'20px',
            background:'black',
            color:'white',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
        }
    };

    const finalStyles = windowWidth <= 768 ? { ...customStyles, ...mobileStyles } : customStyles;


    const GoStudyContent="Many university students struggle to find resources and peers to assist them in their courses. " +
        "\"GoStudy\" is a developing app designed to connect users with local " +
        "tutors and nearby group study sessions. My primary role in this project " +
        "was front-end development."
    const Covid19AppContent="During the COVID-19 pandemic, my team introduced an app to assist doctors in " +
        "monitoring patient health. Using geolocation, the app enabled doctors to communicate with patients and " +
        "identify those with symptoms. Users received alerts if they were near someone " +
        "tagged as \"infected\" or \"at risk.\" Despite its innovation, " +
        "challenges like building public trust and the accuracy of geolocation and user reports arose. " +
        "Nonetheless, the app exemplified our dedication to enhancing pandemic response. " +
        "I was doing Front-end development with the Angular framework during the project ";
    const StackUnderflowContent=" This is a copy of the famous website Stack Overflow. " +
        "It is made with flask framework and SQL for the database. " +
        "We were a team of 6 people developing the website and we followed an agile development model separated in four sprints. " +
        "I did full stack development";
    const RiskContent="A C++ program to simulate the famous Risk board game. It involves the use of a large amounts of pointers " +
        "and it implements Gof's strategic design pattern to allow the player changes their behavior during the game ";

    const InfrastructureContent=" This project is purely object-oriented and made with only Java. " +
        "It involves multiple operations on buildings that extends the structure abstract class. The goal is" +
        " to see the efficiency of the four principles of object-oriented programming which are encapsulation, abstraction, " +
        "polymorphism and inheritance ";

    const openModal = (modalTitle, modalContent) => {
        setTitle(modalTitle);
        setContent(modalContent);
        setIsOpen(true);
    };


    function closeModal() {
        setIsOpen(false);
    }


    return (

        <div>
           <div className="WebsitesContainer">
               <h1>Projects</h1>
               <h2>Websites</h2>
               <ul className="WebsitesGrid">
                   <li className="tilt">
                       <a href="https://rrnotaire.com" target="_blank" rel="noopener noreferrer">
                           <img src="rrnotaire.png" alt="rrnotaire" />
                       </a>
                   </li>
                   <li className="tilt">
                       <a href="https://planetebarbier.com/#" target="_blank" rel="noopener noreferrer">
                           <img src={planetBarbierDirectory} alt="PlaneteBarbier" />
                       </a>
                   </li>
                   <li className="tilt">
                       <a href="https://bosscut786.com/" target="_blank" rel="noopener noreferrer">
                           <img src="bosscut.png" alt="bosscut" />
                       </a>
                   </li>
                   <li className="tilt">
                       <a href="https://mtlmanz.ca/" target="_blank" rel="noopener noreferrer">
                           <img src="mtlmanz.png" alt="mtlmanz" />
                       </a>
                   </li>
                   <li className="tilt">
                       <a href="https://siarportfolio.github.io/" target="_blank" rel="noopener noreferrer">
                           <img src="FirstPortfolio.png" alt="FirstPortfolio" />
                       </a>
                   </li>
               </ul>
           </div>
           <div className="Divider">
               <h3>Hackathons</h3>
           </div>
           <div className="HackathonsContainer">
               <ul className="HackathonsList">
                   <li>
                       <a href="https://devpost.com/software/charitree-t2vczd" target="_blank" rel="noopener noreferrer">
                           <img src="Charitree.png" alt="Charitree" />
                       </a>
                   </li>
                   <li>
                       <a href="https://devpost.com/software/soccerunite" target="_blank" rel="noopener noreferrer">
                           <img src="SoccerUnite.png" alt="SoccerUnite" />
                       </a>
                   </li>
               </ul>
           </div>
           <div className="Divider">
               <h4>Concordia University</h4>
           </div>
            <div className="UniversityContainer">
                <ul className="UniversityGrid">
                    <li className="tilt" onClick={() => openModal('GoStudy', GoStudyContent)}>
                        <img src="GoStudy.png" alt="GoStudy" />
                    </li>
                    <li className="tilt" onClick={() => openModal('Covid 19 App', Covid19AppContent)}>
                        <img src={covidDirectory} alt="Covid19App" />
                    </li>
                    <li className="tilt" onClick={() => openModal('Stack Underflow', StackUnderflowContent)}>
                        <img src="Stack_Underflow.png" alt="Stack_Underflow" />
                    </li>
                    <li className="tilt" onClick={() => openModal('Risk', RiskContent)}>
                        <img src="Risk.png" alt="Risk" />
                    </li>
                    <li className="tilt" onClick={() => openModal('Infrastructures', InfrastructureContent)}>
                        <img src="InfraStructure.png" alt="InfraStructure" />
                    </li>
                </ul>
            </div>

            <Modal isOpen={modalIsOpen}
                   onRequestClose={closeModal}
                   style={finalStyles}
                   contentLabel="Example Modal">
                <button className="ModalClosebutt" onClick={closeModal}><ion-icon name="close-circle-outline"></ion-icon></button>
                <div>
                    <h1>{title}</h1>
                    <p>
                        {content}
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default Projects;