import React from 'react';
import './/StyleComponents/HomeTechnicalSkills.css';




const HomeTechnicalSkills = () => {

    return (

        <div>
            <div className="HomeTechnicalSkillsContainer">
                <h1>
                    Technical Skills
                </h1>
                <ul>
                    Programming Languages
                    <li>
                        Java
                    </li>
                    <li>
                        Python
                    </li>
                    <li>
                        C/C++
                    </li>
                    <li>
                        Html and CSS
                    </li>
                    <li>
                        Javascript
                    </li>
                    <li>
                        SQL
                    </li>
                    <li>
                        PostgresSQL
                    </li>
                    <li>
                        MongoDB
                    </li>
                </ul>

                <ul>
                    Framework
                    <li>
                        React
                    </li>
                    <li>
                        Vue.js
                    </li>
                    <li>
                        Flask
                    </li>
                    <li>
                        Express.js
                    </li>
                </ul>
                <ul>
                    Tools
                    <li>
                        Wordpress
                    </li>
                    <li>
                        Blender
                    </li>
                    <li>
                        Matlab
                    </li>
                    <li>
                        Octave
                    </li>
                    <li>
                        Postman
                    </li>
                    <li>
                        Selenium
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default HomeTechnicalSkills;