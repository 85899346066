import React from 'react';
import './/StyleComponents/WorkPetro.css';




const WorkPetro = () => {

    return (

        <div>
            <div className="WorkPetroContainer">
                <h1>
                    Cashier at Pétro-Canada<br/>
                    2017-2020
                </h1>
                <p style={{paddingBottom:"10%"}}>
                    For three years, I served as a dedicated cashier,
                    mastering transaction efficiency and customer service.
                    My role honed my attention to detail,
                    ensuring accurate financial exchanges.
                    I consistently prioritized customer satisfaction, fostering loyalty through swift
                    and courteous interactions.
                </p>
            </div>
        </div>
    )
}

export default WorkPetro;