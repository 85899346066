import React from 'react';
import './/StyleComponents/WorkReferee.css';




const WorkReferee = () => {

    return (

        <div>
            <div className="WorkRefereeContainer">
                <h1>
                    Soccer Referee<br/>
                    2017-2018
                </h1>
                <p style={{paddingBottom:"10%"}}>
                    For two years, I officiated soccer matches, upholding the integrity of the game.
                    My role demanded quick decision-making under pressure,
                    ensuring fair play and sportsmanship. Navigating dynamic game situations,
                    I refined my conflict resolution skills. This experience solidified
                    my capacity to lead and manage in high-stakes environments.
                </p>
            </div>
        </div>
    )
}

export default WorkReferee;