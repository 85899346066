import React from 'react';
import './/StyleComponents/HomeIntroduction.css';



const HomeIntroduction = () => {

    return (

        <div>
            <div className="RealIntro">
                <p>
                    I am a software engineer who recently graduated from&nbsp;<a className="SuccesScolaire" rel="noreferrer"  target="_blank" href="https://www.concordia.ca/ginacody.html">Gina Cody School of Engineering and Computer Science</a> where
                    I deepened my understanding of various programming languages, data structures,
                    algorithms, and software design principles.
                </p>
            </div>
        </div>
    )
}

export default HomeIntroduction;