import React from 'react';
import './/StyleComponents/HomeCredit.css';


const HomeCredit = () => {

    return (

        <div>
            <div className="HomeCreditContainer">
                <h1>
                    Credit
                </h1>
                <div>
                    <h1>
                        3D model source
                    </h1>
                    <ul>
                        <li>
                            <h2>Desktop: This work is based on "Gaming Desktop PC" (https://sketchfab.com/3d-models/gaming-desktop-pc-d1d8282c9916438091f11aeb28787b66) by Yolala1232 (https://sketchfab.com/Yolala1232) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)</h2>
                        </li>
                        <li>
                            <h2>Soccer ball: This work is based on "Soccer Ball" (https://sketchfab.com/3d-models/soccer-ball-46c91864ef384158b0078e20bdbfe3e9) by typhomnt (https://sketchfab.com/typhomnt) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HomeCredit;