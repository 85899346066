import React from 'react';
import './/StyleComponents/HomeProjects.css';
import LinkButton from "./LinkButton";





const HomeProjects = () => {

    return (

        <div>
            <div className="HomeProjectsContainer">
                <h1>
                    Past Projects
                </h1>
                <p style={{paddingBottom:"10%"}}>
                    During my four years at the university, I participated in various team assignments and did some personal projects . They
                    targeted a specific set of skills relevant in software engineering such as full-stack web development,
                    software architecture, testing & quality assurance, project management, etc.
                </p>
                <div className="projectLink">
                    <LinkButton link="/projects" buttonText="Learn more" ></LinkButton>
                </div>
            </div>

        </div>
    )
}

export default HomeProjects;