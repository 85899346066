import React from 'react';
import './/StyleComponents/WorkWeb.css';
import LinkButton from "./LinkButton";




const WorkWeb = () => {

    return (

        <div>
            <div className="WorkWebContainer">
                <h1>
                    Web developer<br/>
                    2021-now
                </h1>
                <p style={{paddingBottom:"10%"}}>
                    I have been developing and designing websites for two years. I have learned
                    how to communicate with clients, to plan projects so they are delivered on time and
                    within budget, and to troubleshoot issues that arise during development. Over time, I have mastered various
                    tools and technologies that have allowed me to craft user-centric, responsive designs and ensured seamless functionality across different platforms.
                    I believe that continuous learning and adaptation are key in this dynamic field,
                    and I am committed to further honing my skills
                    and contributing meaningfully to the digital landscape.
                </p>
                <div className="webLink">
                    <LinkButton link="/projects" buttonText="See my websites" ></LinkButton>
                </div>
            </div>
        </div>
    )
}

export default WorkWeb;