import React from 'react';
import './/StyleComponents/WorkFactory.css';




const WorkFactory = () => {

    return (

        <div>
            <div className="WorkFactoryContainer">
                <h1>
                    Gordon Food Service<br/>
                    2021
                </h1>
                <p style={{paddingBottom:"10%"}}>
                    During my time at Gordon Food Service, I combined expert truck driving with meticulous merchandise handling.
                    Tasked with vital transport responsibilities, I consistently displayed reliability and time management.
                    Adapting to on-the-spot challenges, my keen attention ensured accurate product positioning.
                    My role demanded effective team communication,
                    emphasizing collaboration and mutual respect in our dynamic environment.
                </p>
            </div>
        </div>
    )
}

export default WorkFactory;