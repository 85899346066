import React from 'react';
import './/StyleComponents/WorkTutor.css';




const WorkTutor = () => {

    return (

        <div>
            <div className="WorkTutorContainer">
                <h1>
                    Online tutor<br/>
                    2020-now
                </h1>
                <p style={{paddingBottom:"10%"}}>
                    Over three years, I've collaborated with&nbsp;<a className="SuccesScolaire" rel="noreferrer"  target="_blank" href="https://www.successcolaire.ca/?gclid=Cj0KCQjw7JOpBhCfARIsAL3bobfV33QBoXZKpzpb4BJyjg8vkDpAd-eaJ8mEFDk3Eg27OiK9NrRqZWwaAttiEALw_wcB">Succès Scolaire</a>
                    &nbsp;and offered private tutoring.
                    Both avenues have deepened my insight into personalized student needs.
                    Together with "Succès Scolaire" and in my private sessions, I've seen the transformative power of dedicated guidance.
                    Each success story fuels my passion for teaching.
                </p>
            </div>
        </div>
    )
}

export default WorkTutor;