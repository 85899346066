import React from 'react';
import FirstAnimation from "../components/FirstAnimation";
import HomePresentation from "../components/HomePresentation";
import HomeProjects from "../components/HomeProjects";
import './StylePages/Home.css';
import HomeTechnicalSkills from "../components/HomeTechnicalSkills";
import HomeEducation from "../components/HomeEducation";
import HomeWork from "../components/HomeWork";
import HomePassion from "../components/HomePassion";
import HomeIntroduction from "../components/HomeIntroduction";
import HomeCredit from "../components/HomeCredit";

const Home = () => {
    return (
        <div>
                <div className="progress-bar-container">
                    <label for="progress-bar">Loading...</label>
                    <progress id="progress-bar" value="0" max="100"></progress>
                </div>
                <main className="MainContainer" style={{ width: "100%", height: "100vh", position: "absolute", zIndex: "0" }}>
                        <HomePresentation/>
                        <HomeIntroduction/>
                        <HomeProjects/>
                        <HomeEducation/>
                        <HomeWork/>
                        <HomeTechnicalSkills/>
                        <HomePassion/>
                        <HomeCredit/>
                </main>
                <FirstAnimation/>
        </div>
    );
};

export default Home;
