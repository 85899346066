import React from 'react';
import './/StyleComponents/BlogContent.css';


const BlogContent=({title, imgSrc, paragraph})=> {

    return (
        <div className="StoryContainer">
            <h1>{title}</h1>
            <img src={imgSrc} alt="storyAlt"/>
            <p>{paragraph}</p>
        </div>
    )

}

export default BlogContent;