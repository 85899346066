import React from 'react';
import './/StyleComponents/HomePresentation.css';



const HomePresentation = () => {

    return (

        <div>
            <div className="Intro">
                <h1>
                   SIAR RADJABI
                </h1>
                <h2>
                    Portfolio
                </h2>
            </div>

        </div>
    )
}

export default HomePresentation;