import React, {useRef} from 'react';
import './/StyleComponents/HomeWork.css';
import WorkWeb from "./WorkWeb";
import WorkTutor from "./WorkTutor";
import WorkFactory from "./WorkFactory";
import WorkPetro from "./WorkPetro";
import WorkReferee from "./WorkReferee";
import useIntersectionObserver from "./useIntersectionObserver";



const HomeWork = () => {

    const HeaderRef = useRef(null);
    const isVisible = useIntersectionObserver(HeaderRef, { rootMargin: '100px' });

    return (


        <div>
            <div  className="HomeWorkContainer">
                <h1  ref={HeaderRef} className={`WorkHeader ${isVisible ? 'visible' : ''}`}>
                    Work experience
                </h1>
                <ul>
                    <li>
                        <WorkWeb></WorkWeb>
                    </li>
                    <li>
                        <WorkTutor></WorkTutor>
                    </li>
                    <li>
                        <WorkFactory></WorkFactory>
                    </li>
                    <li>
                        <WorkPetro></WorkPetro>
                    </li>
                    <li>
                        <WorkReferee></WorkReferee>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default HomeWork;